import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Participant, Room } from 'livekit-client';

export const SelectedParticipantContext = createContext<
  [Participant | null, (participant?: Participant | null) => void]
>(null!);

export default function useSelectedParticipant() {
  const [selectedParticipant, setSelectedParticipant] = useContext(SelectedParticipantContext);
  return [selectedParticipant, setSelectedParticipant] as const;
}

interface ProviderProps {
  room: Room | null;
  children: ReactNode;
}

export function SelectedParticipantProvider({ room, children }: ProviderProps) {
  const [selectedParticipant, _setSelectedParticipant] = useState<Participant | null>(null);
  const setSelectedParticipant = useCallback(
    (participant?: Participant | null) =>
      _setSelectedParticipant((prevParticipant) => (prevParticipant === participant ? null : participant ?? null)),
    []
  );

  useEffect(() => {
    if (room) {
      const onDisconnect = () => _setSelectedParticipant(null);
      const handleParticipantDisconnected = (participant: Participant) =>
        _setSelectedParticipant((prevParticipant) => (prevParticipant === participant ? null : prevParticipant));

      room.on('disconnected', onDisconnect);
      room.on('participantDisconnected', handleParticipantDisconnected);
      return () => {
        room.off('disconnected', onDisconnect);
        room.off('participantDisconnected', handleParticipantDisconnected);
      };
    }
  }, [room]);

  return (
    <SelectedParticipantContext.Provider value={[selectedParticipant, setSelectedParticipant]}>
      {children}
    </SelectedParticipantContext.Provider>
  );
}
