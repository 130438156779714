import overlayStyles from './TableMediaView.module.css';
import styles from './TableScreenShareView.module.css';

import clsx from 'clsx';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VideoTrack } from 'livekit-client';

import useUnmount from 'hooks/useUnmount';
import { MediaAction, TableAction } from 'store/actions';
import { RoomUserSelector } from 'store/selectors';
import { MediaMode, TableViewMode } from 'types/room';
import usePublications from 'video/hooks/usePublications';
import useScreenShareParticipant from 'video/hooks/useScreenShareParticipant';
import useTrack from 'video/hooks/useTrack';

import TableViewOverlay from './TableViewOverlay';
import { isScreenShareTrack } from 'video/utils';

interface TableScreenshareViewProps {
  className?: string;
  isPreview?: boolean;
  room?: any;
}

export default function TableScreenshareView({ className, isPreview, room }: TableScreenshareViewProps) {
  const dispatch = useDispatch();

  const screenShareParticipant = useScreenShareParticipant();
  // @ts-ignore
  const roomUser = useSelector((state) => RoomUserSelector.get(state, room?.guid, screenShareParticipant?.identity));
  const publications = usePublications(screenShareParticipant);
  const track = useTrack(publications.find(isScreenShareTrack)) as VideoTrack | undefined;

  const screenShareRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    track?.attach(screenShareRef.current!);
    return () => {
      track?.detach();
    };
  }, [track]);

  useUnmount(() => {
    dispatch(MediaAction.setMode(MediaMode.None));
  });

  const onMinimizeClick = useCallback(() => {
    dispatch(TableAction.updateViewMode(TableViewMode.Grid));
  }, [dispatch]);

  const onMaximizeClick = useCallback(() => {
    dispatch(TableAction.updateViewMode(TableViewMode.Speaker));
  }, [dispatch]);

  return (
    <div className={clsx(styles.container, className)}>
      <TableViewOverlay
        className={clsx(isPreview && [styles.preview, overlayStyles.previewContainer])}
        showMinimizeButton={!isPreview}
        showMaximizeButton={isPreview}
        showCloseButton={false}
        previewLabel={roomUser && isPreview ? `Screen shared by ${roomUser.username}` : undefined}
        onMinimizeClick={onMinimizeClick}
        onMaximizeClick={onMaximizeClick}
      >
        <div className={styles.videoWrapper}>
          <video ref={screenShareRef} className={styles.video} />
        </div>
      </TableViewOverlay>
    </div>
  );
}
