import { useCallback, useState } from 'react';
import { LocalVideoTrack, LivekitError } from 'livekit-client';

import useVideoContext from './useVideoContext';
import { isScreenShareTrack } from 'video/utils';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'store/selectors';

type ErrorCallback = (error: LivekitError) => void;

function defaultErrorCallback(error: LivekitError) {
  console.dir(error);
}

export default function useLocalVideoToggle(errorCallback: ErrorCallback = defaultErrorCallback) {
  const currentUser = useSelector(SessionSelector.currentUser);
  const { room, localTracks, getLocalVideoTrack, removeLocalVideoTrack } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const videoTrack = localTracks.find(
    (track): track is LocalVideoTrack => track.kind === 'video' && !isScreenShareTrack(track)
  );
  const [isPublishing, setIsPublishing] = useState(false);

  const toggleVideoEnabled = useCallback(
    (forceRepublish = false) => {
      if (!isPublishing) {
        if (videoTrack) {
          localParticipant?.unpublishTrack(videoTrack)
          removeLocalVideoTrack();
        }

        if (!videoTrack || forceRepublish) {
          setIsPublishing(true);
          return getLocalVideoTrack()
          /**
           * LK supports RTCPriorityType but need to setup a VideoPrest, otherwise we would pass { priority: 'low' }
           * */
            .then((track) => {
              track.getDeviceId().then((deviceId) => 
                localParticipant?.publishTrack(track, { name: `camera-${currentUser.username}-${deviceId}`})
              )            
            })
            .catch(errorCallback)
            .finally(() => {
              setIsPublishing(false);
            });
        }
      }
    },
    [getLocalVideoTrack, errorCallback, isPublishing, localParticipant, removeLocalVideoTrack, videoTrack]
  );

  return [!!videoTrack, toggleVideoEnabled] as const;
}
