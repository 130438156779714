import { useEffect } from 'react';
import { Room, ParticipantEvent } from 'livekit-client';

export default function useHandleTrackPublicationFailed(room: Room | null) {
  useEffect(() => {
    if (room) {
      // TODO (danny) this use to be "trackPublicationFailed" from twilio... need to revisit
      room.localParticipant.on(ParticipantEvent.TrackSubscriptionFailed, (error) => console.error(error));
      return () => {
        room.localParticipant.off(ParticipantEvent.TrackSubscriptionFailed, (error) => console.error(error));
      };
    }
  }, [room]);
}
