import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RemoteAudioTrack } from 'livekit-client';

import { LiveKitSelector } from 'store/selectors';

interface AudioTrackProps {
  track: RemoteAudioTrack;
  name: string;
  isLocalParticipant?: boolean;
}

export default function AudioTrack({ track, isLocalParticipant, name }: AudioTrackProps) {
  const { outputDevice } = useSelector(LiveKitSelector.getSettings);
  const ref = useRef<HTMLAudioElement>(null!);

  useEffect(() => {
    const el = ref.current;
    el.setAttribute('data-cy-audio-track-name', name);

    /**
     * TODO (danny): make sure this type coersion works...
     * "This element is based on, and inherits properties and methods from, the HTMLMediaElement interface."
     * see https://developer.mozilla.org/en-US/docs/Web/API/HTMLAudioElement
     */
    track.attach(el as HTMLMediaElement);
    return () => {
      track.detach(el as HTMLMediaElement);
      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;
    };
  }, [isLocalParticipant, track]);

  useEffect(() => {
    // TODO (danny) - what happens if outputDevice is undefined here?
    if (outputDevice) ref.current?.setSinkId?.(outputDevice);
  }, [outputDevice]);
  return <audio ref={ref} />;
}
