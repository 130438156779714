import { createContext, ReactNode } from 'react';
import {
  RoomConnectOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteParticipant,
  Room,
  LocalParticipant,
} from 'livekit-client';

import { getConnectOptions } from 'models/LiveKit';

import useHandleRoomDisconnection from './useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks';
import useParticipants from './useParticipants';
import useRestartAudioTrackOnDeviceChange from './useRestartAudioTrackOnDeviceChange';
import useRoom from './useRoom';
import useScreenShareToggle from './useScreenShareToggle';
import { SelectedParticipantProvider } from './useSelectedParticipant';

export interface IVideoContext {
  connect: (token: string) => Promise<void>;
  getAudioAndVideoTracks: ReturnType<typeof useLocalTracks>['getAudioAndVideoTracks'];
  getLocalAudioTrack: ReturnType<typeof useLocalTracks>['getLocalAudioTrack'];
  getLocalVideoTrack: ReturnType<typeof useLocalTracks>['getLocalVideoTrack'];
  isAcquiringLocalTracks: boolean;
  isConnecting: boolean;
  isSharingScreen: boolean;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  participants: (RemoteParticipant | LocalParticipant)[];
  removeLocalAudioTrack: ReturnType<typeof useLocalTracks>['removeLocalAudioTrack'];
  removeLocalVideoTrack: ReturnType<typeof useLocalTracks>['removeLocalVideoTrack'];
  room: Room | null;
  toggleScreenShare: ReturnType<typeof useScreenShareToggle>[1];
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: RoomConnectOptions;
  children: ReactNode;
}

export function VideoProvider({ children }: VideoProviderProps) {
  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(getConnectOptions());
  const participants = useParticipants(room);
  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room);

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(room, removeLocalAudioTrack, removeLocalVideoTrack);
  useHandleTrackPublicationFailed(room);
  useRestartAudioTrackOnDeviceChange(localTracks);

  return (
    <VideoContext.Provider
      value={{
        localTracks,
        room,
        isConnecting,
        connect,
        getLocalAudioTrack,
        getLocalVideoTrack,
        participants,
        getAudioAndVideoTracks,
        isAcquiringLocalTracks,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        isSharingScreen,
        toggleScreenShare,
      }}
    >
      <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
    </VideoContext.Provider>
  );
}
