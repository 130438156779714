import { useCallback, useState } from 'react';
import useLatest from 'hooks/useLatest';
import { Room, RoomConnectOptions, RoomEvent } from 'livekit-client';
import { getCreateRoomOptions } from 'models/LiveKit';

export default function useRoom(options: RoomConnectOptions) {
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);

  const optionsRef = useLatest(options);
  const connect = useCallback(async (token: string) => {
    setIsConnecting(true);
    if (process.env.REACT_APP_LIVEKIT_URL) {
      const newRoom = new Room(getCreateRoomOptions());
      await newRoom.prepareConnection(process.env.REACT_APP_LIVEKIT_URL, token);
      
      return newRoom.connect(process.env.REACT_APP_LIVEKIT_URL, token, { ...optionsRef.current }).then(() => {
        // if we ever want to auto-on camera and mic, we can do it here?
        // await newRoom.localParticipant.enableCameraAndMicrophone();
  
        const disconnect = () => newRoom.disconnect();
        newRoom.setMaxListeners(16);
        newRoom.once(RoomEvent.Disconnected, () => {
          setTimeout(() => setRoom(null));
          window.removeEventListener('beforeunload', disconnect);
        });
        window.addEventListener('beforeunload', disconnect);
  
        // og had this setRoom right after connect...
        setRoom(newRoom);
      }).catch((connectError) => {
        console.error('Unable to connect - ', connectError);
      }).finally(() => {
        setIsConnecting(false);
      });
    } else {
      console.error('Unable to connect - missing URL');
      return Promise.reject(new Error("REACT_APP_LIVEKIT_URL is not defined."));
    }
  }, []);
  return { room, isConnecting, connect };
}
