import { useEffect, useState } from 'react';
import { LocalTrackPublication, Participant, RemoteTrackPublication, Room, RoomEvent } from 'livekit-client';

import useVideoContext from './useVideoContext';
import { isScreenShareTrack } from 'video/utils';
import { RoomEventCallbacks } from 'livekit-client/dist/src/room/Room';

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

function addMultipleEventListeners(
  room: Room,
  events: (keyof RoomEventCallbacks)[],
  callback: (pub: TrackPublication) => void
) {
  events.forEach((event) => {
    room.on(event, callback);
  });
}

function removeMultipleEventListeners(
  room: Room,
  events: (keyof RoomEventCallbacks)[],
  callback: (pub: TrackPublication) => void
) {
  events.forEach((event) => {
    room.off(event, callback);
  });
}

/*
  Returns the participant that is sharing their screen (if any). This hook assumes that only one participant
  can share their screen at a time.
*/
export default function useScreenShareParticipant() {
  const { room } = useVideoContext();
  const [screenShareParticipant, setScreenShareParticipant] = useState<Participant>();

  useEffect(() => {
    if (room) {
      const updateScreenShareParticipant = () => {
        setScreenShareParticipant(
          Array.from<Participant>(room.remoteParticipants.values() as Iterable<Participant>)
            // TODO: (danny) this conversion may be wrong?
            .concat(room.localParticipant as unknown as Participant)
            .find((participant) => Array.from(participant.videoTrackPublications.values()).find(isScreenShareTrack))
        );
      };
      updateScreenShareParticipant();

      addMultipleEventListeners(room, [RoomEvent.LocalTrackPublished, RoomEvent.TrackPublished, RoomEvent.TrackUnpublished, RoomEvent.LocalTrackUnpublished, RoomEvent.ParticipantDisconnected, RoomEvent.Disconnected], updateScreenShareParticipant);
      return () => {
        removeMultipleEventListeners(room, [RoomEvent.LocalTrackPublished, RoomEvent.TrackPublished, RoomEvent.TrackUnpublished, RoomEvent.LocalTrackUnpublished, RoomEvent.ParticipantDisconnected, RoomEvent.Disconnected], updateScreenShareParticipant);        
      };
    }
  }, [room]);

  return screenShareParticipant;
}
