import styles from './TableLobby.module.css';

import clsx from 'clsx';
import { MouseEventHandler, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RAINBOW_CSS } from 'models/Color';
import { TableAction, LiveKitAction } from 'store/actions';
import { RoomSelector, RoomUserSelector, SessionSelector, LiveKitSelector } from 'store/selectors';
import { pluralize } from 'utilities';
import useRoomState from 'video/hooks/useRoomState';
import useVideoContext from 'video/hooks/useVideoContext';

import LobbyMusic from './LobbyMusic';
import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import FTE from 'components/FTE/FTE';
import LoadingSpinner from 'components/LoadingSpinner';

interface Props {
  className?: string;
  room: any;
  isSmall: boolean;
}

function TableLobby({ className, isSmall, room }: Props) {
  const dispatch = useDispatch();
  const [startButtonRef, setStartButtonRef] = useState<HTMLDivElement | null>(null);
  const currentUser = useSelector(SessionSelector.currentUser);
  const isHostPatron = room.isHostPatron;
  const hasDeviceSaved = useSelector(LiveKitSelector.hasDevice);
  // @ts-ignore RoomUserSelector is not typed yet
  const colors = useSelector((state) => RoomUserSelector.getColors(state, room.guid, currentUser?.id));
  const users = useSelector(RoomUserSelector.getAllPresentInRoom(room.guid));

  const videoToken = useSelector(LiveKitSelector.getToken);
  const { isConnecting: isConnectingToVideoRoom, connect, room: videoRoom } = useVideoContext();
  const roomState = useRoomState();

  const videoRoomStarted = !!room.sid;

  // @ts-ignore RoomSelector is not yet typed properly
  const lobbyMusicUrl = useSelector((state) => RoomSelector.getLobbyMusicUrl(state, room.guid));

  const onJoinClick = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation();

      if (!isHostPatron) {
        dispatch(TableAction.setEntered(true));
        return;
      }

      if (!isConnectingToVideoRoom && roomState === 'disconnected') {
        dispatch(
          LiveKitAction.createVideoRoom(room.guid, () => {
            connect(videoToken).then(() => {
              dispatch(TableAction.showVideoSettings(!hasDeviceSaved));
            });
          })
        );
      }
    },
    [connect, dispatch, hasDeviceSaved, isConnectingToVideoRoom, isHostPatron, room.guid, roomState, videoToken]
  );

  const videoRoomUserCount = users.filter((o) => !!o.sid).length;

  return (
    <div className={clsx(styles.container, className)}>
      {!isSmall && <h3 className={styles.heading}>Ready to play?</h3>}
      <div className={styles.users}>
        {users.map(({ id, avatarUrl, username }) => (
          <div title={`${username} is here!`} key={id}>
            <Avatar
              className={styles.avatar}
              avatarUrl={avatarUrl}
              avatarAlt={username}
              size={36}
              borderColors={RAINBOW_CSS}
              borderSize={2}
              isConic
              showPlayerDefault
            />
          </div>
        ))}
      </div>
      {videoToken && !videoRoom && (
        <>
          {videoRoomStarted && videoRoomUserCount > 0 && (
            <span className={styles.videoRoomUsers}>{`${videoRoomUserCount} ${pluralize(
              videoRoomUserCount,
              'Player'
            )} Live`}</span>
          )}
          {/* @ts-ignore */}
          <FTE refEl={startButtonRef} stepId="start" />
          <div className={styles.buttonWrapper} ref={setStartButtonRef}>
            <Button
              variant="primary"
              onClick={onJoinClick}
              className={styles.joinButton}
              isDisabled={isConnectingToVideoRoom}
              primaryBackground={colors?.[0]}
              secondaryBackground={colors?.[1]}
            >
              {isConnectingToVideoRoom && <LoadingSpinner size={18} />}
              {!isConnectingToVideoRoom && 'Enter'}
            </Button>
            <LobbyMusic roomGuid={room?.guid} url={isConnectingToVideoRoom ? null : lobbyMusicUrl} />
          </div>
        </>
      )}
    </div>
  );
}

export default TableLobby;
