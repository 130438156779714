import { useEffect, useState } from 'react';
import { Participant, RemoteParticipant } from 'livekit-client';

import useVideoContext from './useVideoContext';

/**
 * 
 * LK uses activeSpeakers instead of dominantSpeaker; it is a list and ordered by vol (loudest first)
 * we will simply grab the first of the list (aka the loudest activeSpeaker)
 */
export default function useDominantSpeaker(includeNull = false) {
  const { room } = useVideoContext();
  const [dominantSpeaker, setDominantSpeaker] = useState(room?.activeSpeakers[0] ?? null);

  useEffect(() => {
    if (room) {
      // Sometimes, the 'dominantSpeakerChanged' event can emit 'null', which means that
      // there is no dominant speaker. If we change the main participant when 'null' is
      // emitted, the effect can be jarring to the user. Here we ignore any 'null' values
      // and continue to display the previous dominant speaker as the main participant.
      const handleDominantSpeakerChanged = (newDominantSpeakers: Participant[]) => {
        if (includeNull || newDominantSpeakers.length !== 0) {
          setDominantSpeaker(newDominantSpeakers[0]);
        }
      };

      // Since 'null' values are ignored, we will need to listen for the 'participantDisconnected'
      // event, so we can set the dominantSpeaker to 'null' when they disconnect.
      // TODO (danny) - make sure this works for LK
      const handleParticipantDisconnected = (participant: RemoteParticipant) => {
        setDominantSpeaker((prevDominantSpeaker) => {
          return prevDominantSpeaker === participant ? null : prevDominantSpeaker;
        });
      };

      room.on('activeSpeakersChanged', handleDominantSpeakerChanged);
      if (!includeNull) room.on('participantDisconnected', handleParticipantDisconnected);
      return () => {
        room.off('activeSpeakersChanged', handleDominantSpeakerChanged);
        if (!includeNull) room.off('participantDisconnected', handleParticipantDisconnected);
      };
    }
  }, [room, includeNull]);

  return dominantSpeaker;
}
