import { type AudioTrack, Track, type TrackPublication, type VideoTrack } from 'livekit-client';

type VideoTrackPublication = TrackPublication & {
  kind: 'video';
}

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const audioInputDevices = devices.filter((device) => device.kind === 'audioinput');
  const videoInputDevices = devices.filter((device) => device.kind === 'videoinput');
  return {
    audioInputDevices,
    videoInputDevices,
    audioOutputDevices: devices.filter((device) => device.kind === 'audiooutput'),
    hasAudioInputDevices: audioInputDevices.length > 0,
    hasVideoInputDevices: videoInputDevices.length > 0,
  };
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: 'camera' | 'microphone') {
  const userAgent = navigator.userAgent;
  const permissionName = name as PermissionName; // workaround for https://github.com/microsoft/TypeScript/issues/33923

  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name: permissionName });
      // Safari by default is either prompt or granted, so we show permissions error if state is prompt.
      return result.state === 'denied' || (userAgent.match(/safari/i) && result.state === 'prompt');
    } catch {
      // Firefox does not currently support permissions.query for camera and microphone so we show error always if not granted.
      return userAgent.match(/firefox|fxios/i) || false;
    }
  } else {
    return false;
  }
}

export const SCREENSHARE_TRACK_PREFIX = 'screenshare-';
export const SHARED_AUDIO_TRACK_PREFIX = 'shared-audio-';

export function isScreenShareTrack(track: Track): track is VideoTrack;
export function isScreenShareTrack(publication: TrackPublication): publication is VideoTrackPublication;
export function isScreenShareTrack(t: TrackPublication | Track) {
  return t.source === Track.Source.ScreenShare;
}

export function isSharedAudioTrack(track: Track): track is AudioTrack;
export function isSharedAudioTrack(t: Track) {
  return t.kind === 'audio' && t.source === Track.Source.ScreenShareAudio;
}
