// @flow
import styles from './TablePage.module.css';

import clsx from 'clsx';
import _ from 'lodash';
import type { Node } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AudioPlayerProvider } from 'react-use-audio-player';
import { compose } from 'redux';

import history from 'browserHistory';
import useWindowSize from 'hooks/useWindowSize';
import { Card, shuffle } from 'models/Card';
import useTablePusher from 'room/hooks/useTablePusher';
import {
  GameAction,
  ProductAction,
  RoomAction,
  RoomSheetAction,
  SheetAction,
  TableAction,
  LiveKitAction,
  TwilioAction,
} from 'store/actions';
import {
  CardSelector,
  GameSelector,
  PlaykitSelector,
  ProductSelector,
  RollSelector,
  RoomSelector,
  RoomUserSelector,
  SessionSelector,
  SheetSelector,
  TableSelector,
  TwilioSelector,
} from 'store/selectors';
import { RoomTab } from 'types/room';
import { logEvent } from 'utilities';
import { stylizeTheme } from 'utilities/color';

import requireAuth from 'hocs/requireAuth';
import requireCurrentUser from 'hocs/requireCurrentUser';

import CardDrawer from 'components/CardDrawer';
import CompletedModal from 'components/FTE/CompletedModal';
import FTE from 'components/FTE/FTE';
import RoomFTE from 'components/FTE/RoomFTE';
import JoinTableModal from 'components/JoinTableModal';
import MainLayout from 'components/layouts/MainLayout';
import ManagePlayersModal from 'components/ManagePlayersModal';
import Metatags from 'components/Metatags';
import PageLoading from 'components/PageLoading';
import PlayPanel from 'components/PlayPanel';
import RemovePlayerModal from 'components/RemovePlayerModal';
import RemoveTableModal from 'components/RemoveTableModal';
import RoomControls from 'components/RoomControls';
import TableSettingsModal from 'components/TableSettingsModal';
import TableSheet from 'components/TableSheet';
import TableUserSettingsModal from 'components/TableUserSettingsModal';
import TransferSheetModal from 'components/TransferSheetModal';
import { ReactComponent as D20Icon } from 'images/dice/D20.svg';
import { ReactComponent as BooksIcon } from 'images/icons/BooksIcon.svg';
import { ReactComponent as ChatIcon } from 'images/icons/ChatIcon.svg';
import { ReactComponent as GridViewIcon } from 'images/icons/GridViewIcon.svg';
import { ReactComponent as PriceTagIcon } from 'images/icons/PriceTagIcon.svg';
import { ReactComponent as SheetIcon } from 'images/icons/SheetIcon.svg';
import RoleBackground from 'images/RoleBackground.jpg';
import TableView from 'room/TableView';
import { VideoProvider } from 'video/VideoProvider';

type Props = {
  match: any,
};

function TablePage(props: Props): Node {
  const {
    match: {
      params: { guid },
    },
  } = props;
  const dispatch = useDispatch();
  const size = useWindowSize();
  const currentUser = useSelector(SessionSelector.currentUser);
  const currentTab = useSelector(TableSelector.getCurrentTab);
  const room = useSelector((state) => RoomSelector.get(state, guid));
  const game = useSelector((state) => room && GameSelector.get(state, room.gameId));
  const products = useSelector((state) => (game ? ProductSelector.getLockedByGame(state, game.id) : []));
  const playkits = useSelector((state) => room && PlaykitSelector.getByRoom(state, room.guid));
  const theme = useSelector((state) => TableSelector.getTheme(state, guid));
  const colors = useSelector((state) => RoomUserSelector.getColors(state, guid, currentUser.id));
  const activeSheetUser = useSelector((state) => TableSelector.getActiveSheetUser(state, guid));
  const cardTemplates = useSelector(CardSelector.getTemplates);
  const removeUserId = useSelector(TableSelector.getRemoveUserId);
  const userToRemove = useSelector((state) => removeUserId && RoomUserSelector.get(state, guid, removeUserId));
  const roomToRemove = useSelector(TableSelector.getRoomToRemove);
  const isCurrentUserMember = useSelector((state) => !!RoomUserSelector.get(state, guid, currentUser.id));
  const isShowingSheet = useSelector(TableSelector.isShowingSheet);
  const isTOCOpen = useSelector(TableSelector.isShowingSheetTOC);
  const setIsTOCOpen = useCallback(
    (show) => {
      if (show !== isTOCOpen) dispatch(TableAction.showSheetTOC(show));
    },
    [dispatch, isTOCOpen]
  );
  const isTransferringSheet = useSelector((state) => !!SheetSelector.transferId(state));
  const showCardDrawer = useSelector(TableSelector.showCardDrawer);
  const showTableSettingsModal = useSelector(TableSelector.showSettingsModal);
  const showTableUserSettings = useSelector(TableSelector.isShowingUserSettings);
  const showManageUsers = useSelector(TableSelector.isShowingManageUsers);
  const isShowingPanel = useSelector(TableSelector.isPlayPanelOpen);
  const { showMultiStep, step, showRoomCreate: shouldShowRoomCreate } = useSelector(SessionSelector.getUserFte);
  const showRoomCreate = shouldShowRoomCreate && room?.userId === currentUser.id;
  const [mapFlyoutRef, setMapFlyoutRef] = useState(null);
  const [sheetFlyoutRef, setSheetFlyoutRef] = useState(null);
  const [mobileMapFlyoutRef, setMobileMapFlyoutRef] = useState(null);
  const [mobileSheetFlyoutRef, setMobileSheetFlyoutRef] = useState(null);
  const [hasLoaded, setHasLoaded] = useState({
    decks: false,
    playkits: false,
    room: false,
    roomSheets: false,
    sheets: false,
    users: false,
  });

  const hasLoadedBaseRoom = hasLoaded.room && hasLoaded.users && hasLoaded.playkits;
  const hasLoadedFullRoom = hasLoadedBaseRoom && hasLoaded.decks && hasLoaded.roomSheets && hasLoaded.sheets;
  const showCompletedModal = showMultiStep && step === 4;
  const { title } = room || {};
  const showStoreTab = !!game && products.length > 0;
  const unreadTotal = useSelector((state) =>
    hasLoadedBaseRoom && room && isCurrentUserMember ? TwilioSelector.getUnreadTotal(state, guid) : null
  );
  const dice = useSelector(RollSelector.getDice);
  const modifiers = useSelector(RollSelector.getSelectedNonDiceModifiers);

  const setupCards = useCallback(
    (decks) => {
      cardTemplates.forEach((template) => {
        const deck = decks.find((o) => o.deckId === template.id);

        // If we do not yet have a deck for this template, create it
        if (!deck) {
          let cards = template.cards.map((card) => {
            const { name, value, suit, image } = card;
            const id = `${template.id}-${template.isTarot ? 'tarot' : suit}-${value}`;
            const isFlipped = false;
            const isRotated = Math.random() >= 0.5;
            return new Card({ id, name, value, suit, image, isFlipped, isRotated });
          });
          cards = shuffle(cards);
          dispatch(
            RoomAction.createDeck(guid, {
              room_deck: { deck_id: template.id, is_tarot: template.isTarot, cards: _.mapKeys(cards, 'id') },
            })
          );
          // If any cards have been added, add them to the deck
        } else if (
          deck &&
          _.difference(
            template.cards.map((c) => `${template.id}-${template.isTarot ? 'tarot' : c.suit}-${c.value}`),
            Object.values(deck.cards).map((c) => c.id)
          ).length > 0
        ) {
          const ids = Object.values(deck.cards).map((c) => c.id);
          const newTemplateCards = template.cards.filter(
            (c) => !ids.includes(`${template.id}-${template.isTarot ? 'tarot' : c.suit}-${c.value}`)
          );
          const newCards = newTemplateCards.map((card) => {
            const { name, value, suit, image } = card;
            const id = `${template.id}-${template.isTarot ? 'tarot' : suit}-${value}`;
            const isFlipped = false;
            const isRotated = Math.random() >= 0.5;
            return new Card({ id, name, value, suit, image, isFlipped, isRotated });
          });
          batch(() => {
            newCards.forEach((card) => dispatch(RoomAction.updateDeckCard(guid, deck.deckId, card)));
          });
        }
      });
    },
    [cardTemplates, dispatch, guid]
  );

  useEffect(() => {
    activeSheetUser
      ? dispatch(TableAction.setActiveSheetUser(activeSheetUser.userId))
      : dispatch(TableAction.setActiveSheetUser(currentUser.id));
  }, [activeSheetUser, dispatch, currentUser]);

  // Load necessary room data
  useEffect(() => {
    dispatch(TableAction.setRoomGuid(guid));
    if (showRoomCreate) dispatch(GameAction.fetchAll());

    dispatch(
      RoomAction.fetch(guid, () => {
        setHasLoaded((prev) => ({ ...prev, room: true, users: true }));
        dispatch(RoomAction.fetchPlaykits(guid, () => setHasLoaded((prev) => ({ ...prev, playkits: true }))));
      })
    );

    logEvent('table - view table');

    const query = new URLSearchParams(window.location.search);
    if (query.has('show_settings')) {
      dispatch(TableAction.showSettings(true));
      query.delete('show_settings');
      window.history.replaceState(query, document.title, history.location.pathname);
    }

    return () => {
      batch(() => {
        dispatch(SheetAction.confirmTransfer(null));
        dispatch(TableAction.setRoomGuid(null));
        dispatch(TableAction.reset());
      });
    };
  }, [dispatch, guid, showRoomCreate]);

  // Load the rest of the room data
  useEffect(() => {
    if (!hasLoadedBaseRoom || !isCurrentUserMember || showRoomCreate) return;
    batch(() => {
      dispatch(SheetAction.fetchAll({ room_guid: guid }, () => setHasLoaded((prev) => ({ ...prev, sheets: true }))));
      dispatch(RoomSheetAction.fetchAll(guid, () => setHasLoaded((prev) => ({ ...prev, roomSheets: true }))));
      dispatch(
        RoomAction.fetchDecks(guid, (decks) => {
          setHasLoaded((prev) => ({ ...prev, decks: true }));
          setupCards(decks);
        })
      );
      // probably need both; twilio -> chat, LK -> a/v
      dispatch(TwilioAction.fetchToken(guid));
      dispatch(LiveKitAction.fetchToken(guid));
    });
  }, [dispatch, guid, hasLoadedBaseRoom, isCurrentUserMember, setupCards, showRoomCreate]);

  useEffect(() => {
    if (game?.id) dispatch(ProductAction.fetchAll({ game_id: game.id }));
  }, [dispatch, game?.id]);

  useEffect(() => {
    document.body.classList.add('is-room-page');
    return () => document.body.classList.remove('is-room-page');
  }, []);

  const onAddSheetClick = () => {
    setIsTOCOpen(false);
    batch(() => {
      dispatch(TableAction.addSheet(true));
      dispatch(TableAction.setCurrentTab(RoomTab.Sheets));
    });
  };

  const subtitle = () => {
    if (game) return game.title;
    else if (playkits.length > 0) return playkits[0].title;
    else return null;
  };

  return (
    <AudioPlayerProvider>
      <VideoProvider>
        <MainLayout navChildren={<div />} variant="rainbow-only">
          <Metatags title={title && title !== '' ? `${title} Room` : ''} />
          {hasLoadedBaseRoom && room ? (
            <>
              <div
                className={clsx(styles.container, showRoomCreate && styles.tallContainer)}
                style={theme && stylizeTheme(theme)}
              >
                <div
                  className={clsx(
                    styles.table,
                    !isShowingSheet && styles.hideSheet,
                    isTOCOpen && styles.tocOpen,
                    isShowingPanel && styles.showPanel
                  )}
                >
                  {showRoomCreate ? (
                    <div className={styles.isNewRoomFTE}>
                      <div
                        className={clsx(styles.roomFTEWrapper, room?.backgroundArtUrl && styles.artOverlay)}
                        style={{ backgroundImage: `url("${room?.backgroundArtUrl ?? RoleBackground}")` }}
                      >
                        <RoomFTE roomGuid={guid} />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        ref={setSheetFlyoutRef}
                        className={clsx(
                          styles.tableColumn,
                          styles.sheet,
                          currentTab === RoomTab.Sheets && styles.isActive
                        )}
                      >
                        {hasLoadedFullRoom && activeSheetUser && (
                          <TableSheet room={room} isTOCOpen={isTOCOpen} onChangeTOCOpen={setIsTOCOpen} />
                        )}
                      </div>
                      <div
                        className={clsx(
                          styles.tableColumn,
                          styles.video,
                          currentTab === RoomTab.Party && styles.isActive
                        )}
                      >
                        <TableView room={room} />
                        {showCardDrawer && <CardDrawer room={room} colors={colors} />}
                      </div>
                      <div
                        ref={setMapFlyoutRef}
                        className={clsx(
                          styles.tableColumn,
                          styles.panel,
                          ![RoomTab.Party, RoomTab.Sheets].includes(currentTab) && styles.isActive
                        )}
                      >
                        <PlayPanel room={room} theme={theme} />
                      </div>
                    </>
                  )}
                </div>
              </div>

              {!size.isMd && !showRoomCreate && (
                <>
                  <RoomControls
                    subtitle={subtitle()}
                    room={room}
                    theme={theme}
                    onAddSheet={onAddSheetClick}
                    className={styles.roomControls}
                  />

                  <nav className={styles.navTabs} style={theme && stylizeTheme(theme)}>
                    <button
                      onClick={() => dispatch(TableAction.setCurrentTab(RoomTab.Party))}
                      className={clsx(
                        'button-reset',
                        styles.navButton,
                        currentTab === RoomTab.Party && styles.isActive
                      )}
                    >
                      <GridViewIcon />
                      <span>Party</span>
                    </button>
                    {isCurrentUserMember && (
                      <button
                        onClick={() => {
                          batch(() => {
                            dispatch(TableAction.setPlayPanelContent('Chat'));
                            dispatch(TableAction.setCurrentTab(RoomTab.Chat));
                          });
                        }}
                        className={clsx(
                          'button-reset',
                          styles.navButton,
                          currentTab === RoomTab.Chat && styles.isActive
                        )}
                      >
                        <span className={styles.unreadIndicatorContainer}>
                          {unreadTotal > 0 && <div className={styles.unreadIndicator} />}
                          <ChatIcon />
                        </span>
                        <span>Chat</span>
                      </button>
                    )}
                    <button
                      ref={setMobileSheetFlyoutRef}
                      dataftestep="sheets"
                      onClick={() => dispatch(TableAction.setCurrentTab(RoomTab.Sheets))}
                      className={clsx(
                        'button-reset',
                        styles.navButton,
                        currentTab === RoomTab.Sheets && styles.isActive
                      )}
                    >
                      <SheetIcon />
                      <span>Sheets</span>
                    </button>
                    <button
                      onClick={() => {
                        batch(() => {
                          dispatch(TableAction.setPlayPanelContent('Assets'));
                          dispatch(TableAction.setCurrentTab(RoomTab.Materials));
                        });
                      }}
                      className={clsx(
                        'button-reset',
                        styles.navButton,
                        currentTab === RoomTab.Materials && styles.isActive
                      )}
                    >
                      <BooksIcon />
                      <span>Materials</span>
                    </button>
                    <button
                      ref={setMobileMapFlyoutRef}
                      onClick={() => {
                        batch(() => {
                          dispatch(TableAction.setPlayPanelContent('Dice'));
                          dispatch(TableAction.setCurrentTab(RoomTab.Dice));
                        });
                      }}
                      className={clsx('button-reset', styles.navButton, currentTab === RoomTab.Dice && styles.isActive)}
                    >
                      <span className={styles.unreadIndicatorContainer}>
                        {(dice.length > 0 || modifiers.length > 0) && <div className={styles.unreadIndicator} />}
                        <D20Icon />
                      </span>
                      <span>Dice</span>
                    </button>
                    {showStoreTab && (
                      <button
                        onClick={() => {
                          batch(() => {
                            dispatch(TableAction.setPlayPanelContent('Store'));
                            dispatch(TableAction.setCurrentTab(RoomTab.Store));
                          });
                        }}
                        className={clsx(
                          'button-reset',
                          styles.navButton,
                          currentTab === RoomTab.Store && styles.isActive
                        )}
                      >
                        <PriceTagIcon />
                        <span>Store</span>
                      </button>
                    )}
                  </nav>
                </>
              )}

              {isCurrentUserMember && <TablePusher room={room} />}
              {showTableSettingsModal && <TableSettingsModal room={room} />}
              {showTableUserSettings && <TableUserSettingsModal room={room} />}
              {roomToRemove && (
                <RemoveTableModal room={roomToRemove} onDismiss={() => dispatch(TableAction.remove(null))} />
              )}
              {isTransferringSheet && <TransferSheetModal room={room} />}
              {showManageUsers && <ManagePlayersModal room={room} />}
              {userToRemove && <RemovePlayerModal room={room} user={userToRemove} />}
              {!isCurrentUserMember && <JoinTableModal room={room} onDismiss={() => history.push('/rooms')} />}
              {showCompletedModal && <CompletedModal />}
              <FTE refEl={!size.isMd ? mobileSheetFlyoutRef : sheetFlyoutRef} stepId="sheets" />
              <FTE refEl={!size.isMd ? mobileMapFlyoutRef : mapFlyoutRef} stepId="maps" />
            </>
          ) : (
            <PageLoading />
          )}
        </MainLayout>
      </VideoProvider>
    </AudioPlayerProvider>
  );
}

function TablePusher({ room }: any) {
  useTablePusher(room);
  return null;
}

export default compose(requireAuth, requireCurrentUser)(TablePage);
