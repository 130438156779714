import { useEffect, useState } from 'react';
import { LocalTrackPublication, Participant, ParticipantEvent, RemoteTrackPublication } from 'livekit-client';
import { ParticipantEventCallbacks } from 'livekit-client/dist/src/room/participant/Participant';

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

function addMultipleEventListeners(
  participant: Participant,
  events: (keyof ParticipantEventCallbacks)[],
  callback: (pub: TrackPublication) => void
) {
  events.forEach((event) => {
    participant.on(event, callback);
  });
}

function removeMultipleEventListeners(
  participant: Participant,
  events: (keyof ParticipantEventCallbacks)[],
  callback: (pub: TrackPublication) => void
) {
  events.forEach((event) => {
    participant.off(event, callback);
  });
}

export default function usePublications(participant?: Participant) {
  const [publications, setPublications] = useState<TrackPublication[]>([]);

  useEffect(() => {
    const publicationAdded = (publication: TrackPublication) =>
      setPublications((prevPublications) => [...prevPublications, publication]);
    const publicationRemoved = (publication: TrackPublication) =>
      setPublications((prevPublications) => prevPublications.filter((p) => p !== publication));

    if (participant) {
      // Reset the publications when the 'participant' variable changes.
      setPublications(Array.from(participant.trackPublications.values()) as TrackPublication[]);

      addMultipleEventListeners(participant, [ParticipantEvent.LocalTrackPublished, ParticipantEvent.TrackPublished], (pub) => {
        publicationAdded(pub);
      });
      addMultipleEventListeners(participant, [ParticipantEvent.LocalTrackUnpublished, ParticipantEvent.TrackUnpublished], (pub) => {
        publicationRemoved(pub);
      });      

      return () => {
        removeMultipleEventListeners(participant, [ParticipantEvent.LocalTrackPublished, ParticipantEvent.TrackPublished], (pub) => {
          publicationAdded(pub);
        });
        removeMultipleEventListeners(participant, [ParticipantEvent.LocalTrackUnpublished, ParticipantEvent.TrackUnpublished], (pub) => {
          publicationRemoved(pub);
        });        
      };
    }
  }, [participant]);

  return publications;
}
