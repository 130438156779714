import _ from 'lodash';

import TableAction from 'store/actions/TableAction';
import LiveKitAction from 'store/actions/LiveKitAction';

export const fetchInitialState = () => ({
  settings: {
    audioDevice: localStorage.getItem('currentAudioDevice') || '',
    videoDevice: localStorage.getItem('currentVideoDevice') || '',
    outputDevice: localStorage.getItem('currentOutputDevice') || '',
  },
});

const INITIAL_STATE = {
  isConnectingToVideoRoom: false,
  localTracks: {
    video: null,
    audio: null,
  },
  token: null,
  videoRoom: null,
  ...fetchInitialState(),
};

const liveKitReducer = (state = INITIAL_STATE, action) => {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case LiveKitAction.FETCH_TOKEN:
      return { ...state, token: payload };

    case LiveKitAction.UPDATE_SETTINGS:
      const { audioDevice, videoDevice, outputDevice } = payload;
      if (audioDevice || audioDevice === '') localStorage.setItem('currentAudioDevice', audioDevice);
      if (videoDevice || videoDevice === '') localStorage.setItem('currentVideoDevice', videoDevice);
      if (outputDevice || outputDevice === '') localStorage.setItem('currentOutputDevice', outputDevice);
      return { ...state, settings: { ...state.settings, ...payload } };

    case TableAction.RESET:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default liveKitReducer;
