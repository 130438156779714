import styles from './NetworkQuality.module.css';
import { ConnectionQuality } from 'livekit-client';
import clsx from 'clsx';

export default function NetworkQuality({ quality }: { quality: ConnectionQuality }): JSX.Element {
  let title = '';
  let qualityNumber = 0;

  switch (quality) {
    case ConnectionQuality.Excellent:
      title = 'Excellent';
      qualityNumber = 5;
      break;
    case ConnectionQuality.Good:
      title = 'Good';
      qualityNumber = 3;
      break;
    case ConnectionQuality.Lost:
      title = 'Lost';
      qualityNumber = 0;
      break;
    case ConnectionQuality.Poor:
      title = 'Poor';
      qualityNumber = 1;
      break;
    case ConnectionQuality.Unknown:
      title = 'Unknown';
      qualityNumber = 0;
      break;
    default:
      title = '';
  }

  const qualityClassName = `networkQuality${qualityNumber}`;
  return (
    <div title={title} className={clsx(styles.networkQuality, styles[qualityClassName])}>
      <div className={styles.networkQualityBar} />
      <div className={styles.networkQualityBar} />
      <div className={styles.networkQualityBar} />
      <div className={styles.networkQualityBar} />
      <div className={styles.networkQualityBar} />
    </div>
  );
}
