import {
  LocalTrackPublication,
  RemoteAudioTrack,
  RemoteTrackPublication,
  LocalVideoTrack,
  RemoteVideoTrack,
} from 'livekit-client';

import useTrack from 'video/hooks/useTrack';

import AudioTrack from './AudioTrack';
import VideoTrack from './VideoTrack';
import { isSharedAudioTrack } from 'video/utils';

interface PublicationProps {
  publication?: LocalTrackPublication | RemoteTrackPublication;
  isLocalParticipant?: boolean;
}

export default function Publication({ publication, isLocalParticipant }: PublicationProps) {
  const track = useTrack(publication);

  if (!track || !publication) return null;

  switch (track.kind) {
    case 'audio':
      return isLocalParticipant && !isSharedAudioTrack(track) ? null : (
        <AudioTrack
          track={track as RemoteAudioTrack}
          name={publication.trackName}
          isLocalParticipant={isLocalParticipant}
        />
      );
    case 'video':
      return <VideoTrack track={track as RemoteVideoTrack | LocalVideoTrack} isLocal={isLocalParticipant} />;
    default:
      return null;
  }
}
