import { useCallback, useState } from 'react';
import { LocalAudioTrack, LivekitError } from 'livekit-client';

import useVideoContext from './useVideoContext';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'store/selectors';

type ErrorCallback = (error: LivekitError) => void;

function defaultCallback(error: LivekitError) {
  console.dir(error);
}

export default function useLocalAudioToggle(handleError: ErrorCallback = defaultCallback) {
  const currentUser = useSelector(SessionSelector.currentUser);
  const { room, localTracks, getLocalAudioTrack, removeLocalAudioTrack } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const audioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack | undefined;
  const [isPublishing, setIsPublishing] = useState(false);

  const toggleAudioEnabled = useCallback(
    (forceRepublish = false) => {
      if (!isPublishing) {
        if (audioTrack) {
          localParticipant?.unpublishTrack(audioTrack);
          removeLocalAudioTrack();
        }

        if (!audioTrack || forceRepublish) {
          setIsPublishing(true);
          return getLocalAudioTrack()
          /**
           * LK supports RTCPriorityType but need to setup a AudioPreset, otherwise we would pass { priority: 'standard' }
          * */
            .then((track) => 
              track.getDeviceId().then(deviceId => 
                localParticipant?.publishTrack(track, {
                  name: `mic-${currentUser.username}-${deviceId}`,
                })
              ))
            .catch(handleError)
            .finally(() => {
              setIsPublishing(false);
            });
        }
      }
    },
    [audioTrack, getLocalAudioTrack, handleError, isPublishing, localParticipant, removeLocalAudioTrack]
  );
  return [!!audioTrack, toggleAudioEnabled] as const;
}
