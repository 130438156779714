import { useEffect } from 'react';
import { Room, DisconnectReason, RoomEvent } from 'livekit-client';

export default function useHandleRoomDisconnection(
  room: Room | null,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void
) {
  useEffect(() => {
    if (room) {
      const onDisconnected = (reason?: DisconnectReason) => {
        if (reason) {
          console.error(reason);
        }

        removeLocalAudioTrack();
        removeLocalVideoTrack();
      };

      room.on(RoomEvent.Disconnected, onDisconnected);
      return () => {
        room.off(RoomEvent.Disconnected, onDisconnected);
      };
    }
  }, [room, removeLocalAudioTrack, removeLocalVideoTrack]);
}
