import { mapKeys, merge, omit } from 'lodash';

import RoomAction from 'store/actions/RoomAction';
import LiveKitAction from 'store/actions/LiveKitAction';

const INITIAL_STATE = {};

const roomReducer = (state = INITIAL_STATE, action) => {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case RoomAction.CREATE:
    case RoomAction.FETCH:
    case RoomAction.UPDATE:
      return { ...state, [payload.guid]: payload };

    case RoomAction.DELETE:
      return omit(state, payload);

    case RoomAction.FETCH_ALL:
      return merge({}, state, mapKeys(payload, 'guid'));

    case RoomAction.JOIN:
      const roomJoined = state[payload.guid];
      return {
        ...state,
        [payload.guid]: {
          ...roomJoined,
          userIds: [...roomJoined.userIds, payload.id],
        },
      };

    case LiveKitAction.CREATE_VIDEO_ROOM:
    case RoomAction.UPDATE_SID:
      return { ...state, [payload.guid]: { ...state[payload.guid], sid: payload.sid } };

    case RoomAction.SET_ROOM_BACKGROUND:
      return { ...state, [payload.guid]: { ...state[payload.guid], backgroundArtUrl: payload.backgroundArtUrl } };

    case RoomAction.SET_ROOM_THEME:
      return { ...state, [payload.guid]: { ...state[payload.guid], theme: payload.theme } };

    default:
      return state;
  }
};

export default roomReducer;
