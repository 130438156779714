import styles from 'room/TableUser/TableUser.module.css';

import { useEffect, useRef } from 'react';
import { LocalVideoTrack, RemoteVideoTrack } from 'livekit-client';

interface VideoTrackProps {
  track: LocalVideoTrack | RemoteVideoTrack;
  isLocal?: boolean;
}

export default function VideoTrack({ track }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    track.attach(el);
    return () => {
      track.detach(el);

      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;
    };
  }, [track]);
  return <video ref={ref} className={styles.video} />;
}
