import { useEffect } from 'react';
import { RemoteParticipant, RoomEvent } from 'livekit-client';

import useCallbackAsRef from 'hooks/useCallbackAsRef';

import useVideoContext from './useVideoContext';

type ParticipantCallback = (participant: RemoteParticipant) => void;


export function useParticipantConnected(callback: ParticipantCallback) {
  const { room } = useVideoContext();
  const callbackRef = useCallbackAsRef(callback);

  useEffect(() => {
    if (room) {
      const cb = callbackRef.current;
      room.on(RoomEvent.ParticipantConnected, cb);
      return () => {
        room.off(RoomEvent.ParticipantConnected, cb);
      };
    }
  }, [callbackRef, room]);
}
