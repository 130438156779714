import styles from './AudioPlayer.module.css';

import { clamp, debounce } from 'lodash';
import { ChangeEventHandler, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAudioPlayer, useAudioPosition } from 'react-use-audio-player';
import { LocalAudioTrack, LocalTrackPublication } from 'livekit-client';

import useCallbackAsRef from 'hooks/useCallbackAsRef';
import usePrevious from 'hooks/usePrevious';
import useUnmount from 'hooks/useUnmount';
import { AudioPlayerAction, RoomUserAction } from 'store/actions';
import { AudioPlayerSelector, RoomUserSelector, SessionSelector } from 'store/selectors';
import { secondsToTimestamp } from 'utilities/numbers';
import useVideoContext from 'video/hooks/useVideoContext';

import { ArrowPathIcon, PauseCircleIcon, PlayCircleIcon } from '@heroicons/react/24/solid';
import AssetTray from 'components/AssetTray';
import IconButton from 'components/buttons/IconButton';
import { ReactComponent as DoubleArrowIcon } from 'images/icons/DoubleArrowIcon.svg';
import { SHARED_AUDIO_TRACK_PREFIX } from 'video/utils';

interface AudioPlayerProps {
  room: any;
}

export default function AudioPlayer({ room }: AudioPlayerProps) {
  return (
    <div className={styles.container}>
      <AssetTray room={room} className={styles.assetTray} category="audio" />
      <Controls roomGuid={room.guid} />
    </div>
  );
}

export function Controls({ roomGuid }: { roomGuid: string }) {
  const dispatch = useDispatch();
  const { room } = useVideoContext();
  const currentUser = useSelector(SessionSelector.currentUser);
  const userSettings = useSelector((state) =>
    // @ts-ignore RoomUserSelector is not typed yet
    roomGuid && currentUser ? RoomUserSelector.getSettings(state, roomGuid, currentUser.id) : {}
  );
  const inRoom = room && room.state === 'connected';

  const currentAsset = useSelector(AudioPlayerSelector.getActiveAudioAsset);
  const previousAsset = usePrevious(currentAsset);

  const [publication, setPublication] = useState<LocalTrackPublication | null>(null);
  const localAudioNode = useRef<HTMLAudioElement | null>(null);

  const { togglePlayPause, playing, load, player, stop, play, volume: howlVolume } = useAudioPlayer();
  const [isLooping, setIsLooping] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [volume, setVolume] = useState(userSettings?.audioVolume ?? howlVolume() ?? 1);

  useEffect(() => {
    howlVolume(volume);
  }, [howlVolume, volume]);

  const publishStream = useCallbackAsRef(async () => {
    if (inRoom) {
      setIsPublishing(true);
      Howler.mute(false);
      const streamOutput = Howler.ctx.createMediaStreamDestination();

      Howler.masterGain.disconnect();
      Howler.masterGain.connect(streamOutput);

      // In-memory audio element for initializing local audio stream
      localAudioNode.current = document.createElement('audio');
      localAudioNode.current.srcObject = streamOutput.stream;

      const audioStream = streamOutput.stream.getAudioTracks()[0];
      const track = new LocalAudioTrack(audioStream);
      const pub = await room.localParticipant.publishTrack(track, {
        name: `${SHARED_AUDIO_TRACK_PREFIX}${audioStream.id}`,
      });
      setPublication(pub);

      // Actually play the local audio stream so that we have data to send across webRTC
      localAudioNode.current.play();

      setIsPublishing(false);
    }
  });

  const unpublishStream = useCallbackAsRef(() => {
    if (publication && !player?.loop()) {
      const track = publication.audioTrack;

      if (track) {
        room?.localParticipant.unpublishTrack(track);
      }

      // Garbage collect our local audio element
      if (localAudioNode.current) {
        localAudioNode.current.srcObject = null;
        localAudioNode.current.remove();
      }

      dispatch(AudioPlayerAction.setRoomDocument(null));
      setPublication(null);
    }
  });

  const playStream = useCallbackAsRef(() => {
    if (!publication && !isPublishing) {
      publishStream.current();
    } else {
      // TODO (danny) make sure this is working correctly
      publication?.track?.resumeUpstream();
    }
  });

  const pauseStream = useCallbackAsRef(() => {
    publication?.track?.pauseUpstream();
  });

  const loadAsset = useCallbackAsRef((asset: any) => {
    // @ts-ignore _src is not exposed via TS api. This replays the current track if the last src is the same as the asset being played
    if (player?._src === asset?.fileUrl) {
      stop();
      play();
    } else
      load({
        src: asset?.fileUrl,
        autoplay: true,
        volume,
        html5: false,
        loop: isLooping,
        onload: () => publishStream.current(),
        onend: () => unpublishStream.current(),
        onplay: () => playStream.current(),
        onpause: () => pauseStream.current(),
      });
  });

  useEffect(() => {
    if (currentAsset && currentAsset?.id !== previousAsset?.id) loadAsset.current(currentAsset);
  }, [currentAsset, loadAsset, play, playing, previousAsset]);

  useUnmount(() => {
    dispatch(AudioPlayerAction.setRoomDocument(null));
  });

  const throttledUpdateSettings = useMemo(
    () =>
      debounce(
        (volume) => dispatch(RoomUserAction.updateSettings(roomGuid, currentUser.id, { audioVolume: volume })),
        500
      ),
    [currentUser.id, dispatch, roomGuid]
  );
  const onAdjustVolume = (volume: number) => {
    setVolume(volume);
    throttledUpdateSettings(volume);
  };

  const onClickLeft = useCallback(() => {
    stop();
    togglePlayPause();
  }, [stop, togglePlayPause]);

  const onClickRight = useCallback(() => {
    stop();
    unpublishStream.current();
    if (isLooping) togglePlayPause();
  }, [isLooping, stop, togglePlayPause, unpublishStream]);

  const onClickPlayPause = useCallback(() => {
    togglePlayPause();
  }, [togglePlayPause]);

  const onClickLoop = useCallback(() => {
    player?.loop(!isLooping);
    setIsLooping(!isLooping);
  }, [isLooping, player]);

  return (
    <div className={styles.player}>
      <label className={styles.trackLabel}>{currentAsset?.name || '\u00A0'}</label>
      <div className={styles.controls}>
        <VolumeControl volume={volume} setVolume={onAdjustVolume} />
        <IconButton
          iconSize={24}
          color="rgb(var(--color-theme-text))"
          background="rgb(var(--color-theme-button))"
          hoverBackground="rgb(var(--color-theme-accent))"
          activeBackground="rgb(var(--color-theme-accent))"
          children={<DoubleArrowIcon className={styles.arrowLeft} />}
          onClick={onClickLeft}
          isDisabled={!currentAsset}
        />
        <IconButton
          iconSize={24}
          color="rgb(var(--color-theme-text))"
          background="rgb(var(--color-theme-button))"
          hoverBackground="rgb(var(--color-theme-accent))"
          activeBackground="rgb(var(--color-theme-accent))"
          children={playing ? <PauseCircleIcon /> : <PlayCircleIcon />}
          onClick={onClickPlayPause}
          isDisabled={!currentAsset}
        />
        <IconButton
          iconSize={24}
          color="rgb(var(--color-theme-text))"
          background="rgb(var(--color-theme-button))"
          hoverBackground="rgb(var(--color-theme-accent))"
          activeBackground="rgb(var(--color-theme-accent))"
          children={<DoubleArrowIcon className={styles.arrowRight} />}
          onClick={onClickRight}
          isDisabled={!currentAsset}
        />
        <IconButton
          className={styles.loopButton}
          iconSize={24}
          isActive={isLooping}
          color="rgb(var(--color-theme-text))"
          background="rgb(var(--color-theme-button))"
          hoverBackground="rgb(var(--color-theme-accent))"
          activeBackground="rgb(var(--color-theme-accent))"
          children={<ArrowPathIcon />}
          onClick={onClickLoop}
        />
      </div>
      <SeekBar />
    </div>
  );
}

function SeekBar() {
  const { duration, position, percentComplete, seek } = useAudioPosition({ highRefreshRate: true });

  const [barWidth, setBarWidth] = useState('0%');
  const seekBarRef = useRef<HTMLDivElement>(null);

  const goTo = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (seekBarRef.current) {
        const rect = seekBarRef.current.getBoundingClientRect();
        const percent = (event.clientX - rect.left) / rect.width;
        seek(percent * duration);
      }
    },
    [duration, seek]
  );

  useEffect(() => {
    setBarWidth(`${percentComplete}%`);
  }, [percentComplete]);

  return (
    <div className={styles.trackPosition}>
      <div ref={seekBarRef} className={styles.seekBar} onClick={goTo}>
        <div style={{ width: barWidth }} className={styles.progress} />
      </div>
      <span>
        {secondsToTimestamp(position)} / {secondsToTimestamp(duration)}
      </span>
    </div>
  );
}

export function VolumeControl({ volume, setVolume }: { volume: number; setVolume: (volume: number) => void }) {
  // Input value is the 4th root of the set volume
  const inputValue = clamp(Math.pow(volume ?? 1, 1 / 4), 0, 1);

  const onChangeVolume = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const value = event.currentTarget.valueAsNumber;
      // Volume is not linear, 4th power is approximation of logarithmic scale https://www.dr-lex.be/info-stuff/volumecontrols.html
      setVolume(clamp(Math.pow(value, 4), 0, 1));
    },
    [setVolume]
  );

  return (
    <div className={styles.volumeSlider} style={{ '--volume-width-percent': inputValue }}>
      <input value={inputValue} type="range" min={0} max={1} step={0.01} onInput={onChangeVolume} />
    </div>
  );
}
