import styles from './VideoSettingsModal.module.css';

import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalVideoTrack,
  LivekitError,
} from 'livekit-client';

import useUnmount from 'hooks/useUnmount';
import { getLocalVideoOptions } from 'models/Twilio';
import { ErrorAction, TableAction, LiveKitAction } from 'store/actions';
import { ErrorSelector, SessionSelector, LiveKitSelector } from 'store/selectors';
import { DeviceError } from 'types/video';
import useDevices from 'video/hooks/useDevices';

import MicLevel from './MicLevel';
import Button from 'components/buttons/Button';
import MediaError, { createDeviceError } from 'components/errors/MediaError';
import { DropdownInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';
import { ReactComponent as VideoOffIcon } from 'images/icons/VideoOffIcon.svg';
import { isPermissionDenied } from 'video/utils';

const VIDEO_ERROR_KEY = 'modal_video_error';
const AUDIO_ERROR_KEY = 'modal_audio_error';

export default function VideoSettingsModal() {
  const dispatch = useDispatch();
  const currentUser = useSelector(SessionSelector.currentUser);
  const settings = useSelector(LiveKitSelector.getSettings);

  const [audioInputDevice, setAudioInputDevice] = useState(settings.audioDevice ?? '');
  const [videoInputDevice, setVideoInputDevice] = useState(settings.videoDevice ?? '');
  const [audioOutputDevice, setAudioOutputDevice] = useState(settings.outputDevice ?? '');
  const previewAudioTrack = useRef<LocalAudioTrack>();
  const previewVideoTrack = useRef<LocalVideoTrack>();
  const previewVideoElement = useRef<HTMLVideoElement>(null);

  // @ts-ignore need to create a useAppSelector wth proper types when we get to that
  const videoError = useSelector((state) => ErrorSelector.get(state, VIDEO_ERROR_KEY));
  // @ts-ignore need to create a useAppSelector wth proper types when we get to that
  const audioError = useSelector((state) => ErrorSelector.get(state, AUDIO_ERROR_KEY));

  const onSelectAudioDevice = useCallback(
    async (deviceId: string) => {
      previewAudioTrack.current?.stop();
      dispatch(ErrorAction.remove(AUDIO_ERROR_KEY));
      if (!deviceId) {
        previewAudioTrack.current = undefined;
        setAudioInputDevice('');
      } else {
        await createLocalAudioTrack({
          deviceId: { exact: deviceId },
        })
          .then((track) => {
            previewAudioTrack.current = track;
            setAudioInputDevice(deviceId);
          })
          .catch((error: LivekitError) => {
            console.dir(error);
            dispatch(ErrorAction.create(createDeviceError(AUDIO_ERROR_KEY, error.name as DeviceError, 'microphone')));
          });
      }
    },
    [currentUser.username, dispatch]
  );

  const onSelectVideoDevice = useCallback(
    async (deviceId: string) => {
      previewVideoTrack.current?.stop();
      previewVideoTrack.current?.detach();
      dispatch(ErrorAction.remove(VIDEO_ERROR_KEY));

      if (!deviceId) {
        previewVideoTrack.current = undefined;
        previewVideoElement.current!.src = ''; // Makes it so we don't have to unmount the video element to show the default background image
        setVideoInputDevice('');
      } else {
        return createLocalVideoTrack({
          deviceId: { exact: deviceId },
          ...getLocalVideoOptions('premium'),
        })
          .then((track) => {
            previewVideoTrack.current = track;
            previewVideoTrack.current.attach(previewVideoElement.current!);
            setVideoInputDevice(deviceId);
          })
          .catch((error: LivekitError) => {
            console.dir(error);
            dispatch(ErrorAction.create(createDeviceError(VIDEO_ERROR_KEY, error.name as DeviceError, 'camera')));
          });
      }
    },
    [currentUser.username, dispatch]
  );

  const onSelectAudioOutputDevice = useCallback(async (deviceId: string) => {
    setAudioOutputDevice(deviceId);
  }, []);

  const onDismiss = useCallback(() => {
    previewVideoTrack.current?.stop();
    previewVideoTrack.current?.detach();
    previewAudioTrack.current?.stop();

    dispatch(TableAction.showVideoSettings(false));
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    dispatch(
      LiveKitAction.updateSettings({
        videoDevice: videoInputDevice,
        audioDevice: audioInputDevice,
        outputDevice: audioOutputDevice,
      })
    );
    onDismiss();
  }, [audioInputDevice, audioOutputDevice, dispatch, onDismiss, videoInputDevice]);

  const [devices, getDevices] = useDevices();

  // Setup initial preview tracks
  const [isSetup, setIsSetup] = useState(false);
  useEffect(() => {
    if (!isSetup) {
      const checkPermissions = async () => {
        const isCameraPermissionDenied = await isPermissionDenied('camera');
        const isMicrophonePermissionDenied = await isPermissionDenied('microphone');
        batch(() => {
          if (isCameraPermissionDenied)
            dispatch(
              ErrorAction.create(createDeviceError(VIDEO_ERROR_KEY, 'NotAllowedError' as DeviceError, 'camera'))
            );
          if (isMicrophonePermissionDenied)
            dispatch(
              ErrorAction.create(createDeviceError(AUDIO_ERROR_KEY, 'NotAllowedError' as DeviceError, 'microphone'))
            );
        });
      };

      // Ask for audio and video permissions so we can enumerate devices
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then(async (stream) => {
          // We need to fetch the devices before the tracks are stopped because otherwise temporary perms in firefox will give empty labels
          await getDevices();

          stream.getAudioTracks().forEach((track) => {
            track.stop();
          });
          stream.getVideoTracks().forEach((track) => {
            track.stop();
          });

          const promises = [];
          // Setup initial previews
          if (settings.audioDevice) promises.push(onSelectAudioDevice(settings.audioDevice));
          if (settings.videoDevice) promises.push(onSelectVideoDevice(settings.videoDevice));
          if (settings.outputDevice) promises.push(onSelectAudioOutputDevice(settings.outputDevice));
          await Promise.all(promises);
        })
        .catch((error: LivekitError) => {
          checkPermissions();
          console.dir(error);
        })
        .finally(() => setIsSetup(true));
    }
  }, [
    dispatch,
    getDevices,
    isSetup,
    onSelectAudioDevice,
    onSelectAudioOutputDevice,
    onSelectVideoDevice,
    settings.audioDevice,
    settings.outputDevice,
    settings.videoDevice,
  ]);

  useUnmount(() => {
    batch(() => {
      dispatch(ErrorAction.remove(VIDEO_ERROR_KEY));
      dispatch(ErrorAction.remove(AUDIO_ERROR_KEY));
    });
  });

  return (
    <TitleModal
      title="Audio & Video Settings"
      onDismiss={onDismiss}
      width="auto"
      actions={
        <>
          <Button variant="cancel" onClick={onDismiss}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" onClick={onSubmit} isLoading={!isSetup}>
            Save Settings
          </Button>
        </>
      }
    >
      {(audioError || videoError) && <MediaError audioError={audioError} videoError={videoError} />}

      <div className={styles.videoContainer}>
        <div className={styles.videoWrapper}>
          <VideoOffIcon className={styles.videoOffIcon} />
          <video ref={previewVideoElement} className={clsx(styles.video)} />
        </div>
      </div>

      <div className={styles.soundSettings}>
        <DeviceSelectionList
          devices={isSetup ? devices.videoInputDevices : []}
          isDisabled={!isSetup}
          label="Camera:"
          onChange={onSelectVideoDevice}
          value={videoInputDevice}
        />

        <DeviceSelectionList
          devices={isSetup ? devices.audioInputDevices : []}
          isDisabled={!isSetup}
          label="Microphone:"
          onChange={onSelectAudioDevice}
          value={audioInputDevice}
        />

        <div className={styles.levelsField}>
          <label className={styles.inputLabel} />
          <MicLevel start={isSetup} track={previewAudioTrack.current} />
        </div>

        <DeviceSelectionList
          devices={isSetup ? devices.audioOutputDevices : []}
          isDisabled={!isSetup}
          label="Audio Output:"
          noneLabel="System Default"
          onChange={onSelectAudioOutputDevice}
          value={audioOutputDevice}
        />
      </div>
    </TitleModal>
  );
}

interface DeviceSelectionListProps {
  className?: string;
  devices: MediaDeviceInfo[];
  label: string;
  noneLabel?: string;
  onChange: (deviceId: string) => void;
  value: string;
  isDisabled?: boolean;
}

function DeviceSelectionList({
  className,
  devices,
  label,
  noneLabel,
  onChange,
  value,
  isDisabled,
}: DeviceSelectionListProps) {
  return (
    <label className={clsx(styles.inputContainer, className)}>
      <span className={styles.inputLabel}>{label}</span>
      <DropdownInput
        value={value}
        isDisabled={isDisabled || !devices.length}
        className={styles.select}
        onChange={onChange}
      >
        <option value="">{noneLabel || 'None'}</option>
        {devices.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label}
          </option>
        ))}
      </DropdownInput>
    </label>
  );
}
