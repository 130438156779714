import _ from 'lodash';
import { createSelector } from 'reselect';

import RoomSelector from './RoomSelector';
import SessionSelector from './SessionSelector';
import Client from '@twilio/conversations';

// We have to pull this type out because its not directly exported. Thanks Twilio!
type Conversation = Awaited<ReturnType<Client['createConversation']>>;
type Paginator = Awaited<ReturnType<Conversation['getMessages']>>;
type Message = Paginator['items'][0];

interface StateStub {
  twilio: {
    /**
     * Chat
     */
    chatClient: Client;
    isConnectingToChat: boolean;
    conversations: Record<string, Conversation>;
    messages: Record<string, Message[]>;
    paginators: Record<string, Paginator>;
    selectedConversationName: string;
    unreadCounts: Record<string, number>;
    token: string;
  };
}

export default class TwilioSelector {
  static getChatClient = (state: StateStub) => state.twilio.chatClient;

  static getConversations = createSelector(
    (state: StateStub) => state.twilio.conversations,
    (models) => Object.values(models)
  );

  static getDiceConversation = createSelector(
    [RoomSelector.get, (state: StateStub) => state.twilio.conversations],
    (room, models) => Object.values(models).find((o) => o.uniqueName === `${room.guid}::Dice`)
  );

  static getEveryoneConversation = createSelector(
    [RoomSelector.get, (state: StateStub) => state.twilio.conversations],
    (room, models) => Object.values(models).find((o) => o.uniqueName === `${room.guid}::Everyone`)
  );

  static getMessages = (state: StateStub, sid: string) => state.twilio.messages[sid] || [];

  static getMessagesForEverything = createSelector(
    [this.getEveryoneConversation, this.getDiceConversation, (state: StateStub) => state.twilio.messages],
    (everyoneConversation, diceConversation, messages) => {
      const everyoneMessages = everyoneConversation ? messages[everyoneConversation.sid] || [] : [];
      const diceMessages = diceConversation ? messages[diceConversation.sid] || [] : [];
      return _.sortBy([...everyoneMessages, ...diceMessages], (o) => o.dateCreated);
    }
  );

  static getPaginator = (state: StateStub, sid: string) => state.twilio.paginators[sid];

  static getPaginatorForDice = createSelector(
    this.getDiceConversation,
    (state: StateStub) => state.twilio.paginators,
    (conversation, paginators) => (conversation ? paginators[conversation?.sid] : undefined)
  );

  static getPaginatorForEveryone = createSelector(
    this.getEveryoneConversation,
    (state: StateStub) => state.twilio.paginators,
    (conversation, paginators) => (conversation ? paginators[conversation.sid] : undefined)
  );

  static getPaginators = createSelector(
    (state: StateStub) => state.twilio.paginators,
    (models) => Object.entries(models)
  );

  static getSelectedConversation = createSelector(
    [
      SessionSelector.currentUser,
      RoomSelector.get,
      (state: StateStub) => state.twilio.conversations,
      (state: StateStub) => state.twilio.selectedConversationName,
    ],
    (currentUser, room, models, selectedConversationName) => {
      if (!selectedConversationName || selectedConversationName === 'Everything') return null;
      return Object.values(models).find((o) => {
        if (selectedConversationName === 'Everyone' || selectedConversationName === 'Dice') {
          return o.uniqueName === `${room.guid}::${selectedConversationName}`;
        } else if (selectedConversationName.includes('Private')) {
          const id = selectedConversationName.split('::')[1];
          return (
            o.uniqueName.includes(`${room.guid}::Private::${currentUser.id}::${id}`) ||
            o.uniqueName.includes(`${room.guid}::Private::${id}::${currentUser.id}`)
          );
        } else {
          return null;
        }
      });
    }
  );

  static getSelectedConversationName = (state: StateStub) => state.twilio.selectedConversationName;

  static getToken = (state: StateStub) => state.twilio.token;

  static getUnreadCounts = createSelector(
    (state: StateStub, sid: string) => state.twilio.unreadCounts,
    (counts) => Object.entries(counts)
  );

  static getUnreadTotal = createSelector(
    [(state: StateStub) => state.twilio.unreadCounts, this.getDiceConversation],
    (counts, diceConversation) =>
      Object.entries(counts)
        .filter((o) => !diceConversation || o[0] !== diceConversation.sid)
        .reduce((total, o) => total + o[1], 0)
  );

  static isConnectingToChat = (state: StateStub) => state.twilio.isConnectingToChat;
}
