import _ from 'lodash';
import { createSelector } from 'reselect';

export type LiveKitVideoSettings = {
  audioDevice?: string;
  videoDevice?: string;
  outputDevice?: string;
};

// Chat will continue via twilio until we can upgrade LK to newer version
interface StateStub {
  liveKit: {
    settings: LiveKitVideoSettings;
    token: string;    
  }
};

export default class LiveKitSelector {
  static getSettings = (state: StateStub) => state.liveKit.settings;

  static getToken = (state: StateStub) => state.liveKit.token;

  static hasDevice = createSelector(this.getSettings, (settings) => !!settings.audioDevice || !!settings.videoDevice);
}