import { Dispatch } from 'redux';

import { createAction, handleError } from './utilities';

import { SessionSelector } from 'store/selectors';
import role from 'apis/role';
import { LiveKitVideoSettings } from 'store/selectors/LiveKitSelector';

// TODO (danny): in order to move to LK chat, we need to upgrade the LK SDK...
export default class LiveKitAction {
  static CONNECTING_VIDEO_ROOM = 'LiveKitAction.CONNECTING_VIDEO_ROOM';
  static CREATE_VIDEO_ROOM = 'LiveKitAction.CREATE_VIDEO_ROOM';
  static FETCH_TOKEN = 'LiveKitAction.FETCH_TOKEN';
  static UPDATE_LOCAL_TRACKS = 'LiveKitAction.UPDATE_LOCAL_TRACKS';
  static UPDATE_SETTINGS = 'LiveKitAction.UPDATE_SETTINGS';

  static createVideoRoom =
    (guid: string, callback?: (sid: string) => void) => async (dispatch: Dispatch, getState: () => any) => {
      dispatch(createAction(LiveKitAction.CONNECTING_VIDEO_ROOM, true));
      const data = { socket_id: SessionSelector.getSocketId(getState()) };
      return role.post(`/rooms/${guid}/video_room`, data).then(
        (response) => {
          const sid = response.data.sid;
          dispatch(createAction(LiveKitAction.CREATE_VIDEO_ROOM, { guid, sid }));
          callback?.(sid);
        },
        ({ response }) => handleError(dispatch, LiveKitAction.CREATE_VIDEO_ROOM, response)
      );
    };

  static fetchToken = (guid: string, callback?: (token: string) => void) => async (dispatch: Dispatch) => {
    return role.get(`/rooms/${guid}/video_token`).then(
      (response) => {
        const { token } = response.data;
        dispatch(createAction(LiveKitAction.FETCH_TOKEN, token));
        callback?.(token);
      },
      ({ response }) => handleError(dispatch, LiveKitAction.FETCH_TOKEN, response)
    );
  };

  static updateSettings = (settings: LiveKitVideoSettings) => {
    return createAction(LiveKitAction.UPDATE_SETTINGS, settings);
  };
}