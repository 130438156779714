import { applyMiddleware, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';

import reducers from './store/reducers';
import { fetchInitialState as fetchInitialSessionState } from 'store/reducers/sessionReducer';
import { fetchInitialState as fetchInitialLiveKitState } from 'store/reducers/liveKitReducer';

import { getAuthCookie } from 'apis/role';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Setup for SSR
const preloadedState = window.__PRELOADED_STATE__;

delete window.__PRELOADED_STATE__;

/**
 * Grab browser storage data on page load
 *
 * Any reducer which has INITIAL_STATE that depends on browser storage needs to export
 * fetchInitialState() and get checked here.
 */
const token = getAuthCookie();
if (preloadedState?.liveKit) Object.assign(preloadedState.liveKit, fetchInitialLiveKitState());
if (preloadedState?.session && token) Object.assign(preloadedState.session, fetchInitialSessionState());

const store = createStore(reducers, preloadedState, composeEnhancers(applyMiddleware(reduxThunk)));

window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState(),
});

export default store;
