import _ from 'lodash';

import TableAction from 'store/actions/TableAction';
import TwilioAction from 'store/actions/TwilioAction';

const INITIAL_STATE = {
  chatClient: null,
  conversations: {},
  isConnectingToChat: false,
  messages: {},
  paginators: {},
  selectedConversationName: 'Everything',
  token: null,
  unreadCounts: {},
};

const twilioReducer = (state = INITIAL_STATE, action) => {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case TwilioAction.ADD_CONVERSATION:
      return { ...state, conversations: { ...state.conversations, [payload.sid]: payload } };

    case TwilioAction.ADD_MESSAGE:
      const messages = state.messages[payload.sid];
      if (messages) return { ...state, messages: { ...state.messages, [payload.sid]: [...messages, payload.message] } };
      else return state;

    case TwilioAction.CONNECT_CHAT:
      return { ...state, chatClient: payload };

    case TwilioAction.CONNECTING_CHAT:
      return { ...state, isConnectingToChat: payload };

    case TwilioAction.FETCH_TOKEN:
      return { ...state, token: payload };

    case TwilioAction.INCREMENT_UNREAD_COUNT:
      const currentCount = state.unreadCounts[payload.sid] || 0;
      return { ...state, unreadCounts: { ...state.unreadCounts, [payload.sid]: currentCount + (payload.count || 0) } };

    case TwilioAction.REMOVE_CONVERSATION:
      return {
        ...state,
        conversations: _.omit(state.conversations, payload),
        messages: _.omit(state.messages, payload),
        paginators: _.omit(state.paginators, payload),
      };

    case TwilioAction.SELECT_CONVERSATION:
      return { ...state, selectedConversationName: payload };

    case TwilioAction.UPDATE_MESSAGES:
      return { ...state, messages: { ...state.messages, [payload.sid]: payload.messages } };

    case TwilioAction.UPDATE_PAGINATOR:
      return { ...state, paginators: { ...state.paginators, [payload.sid]: payload.paginator } };

    case TwilioAction.UPDATE_UNREAD_COUNT:
      return { ...state, unreadCounts: { ...state.unreadCounts, [payload.sid]: payload.count || 0 } };

    case TableAction.RESET:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default twilioReducer;
