import { useEffect, useState } from 'react';
import { Participant, ConnectionQuality, ParticipantEvent } from 'livekit-client';

//TODO(danny): rename to connection quality
export default function useParticipantNetworkQualityLevel(participant?: Participant) {
  const [networkQualityLevel, setNetworkQualityLevel] = useState(participant?.connectionQuality);

  useEffect(() => {
    if (participant) {
      const handleNetworkQualityLevelChange = (newNetworkQualityLevel: ConnectionQuality) =>
        setNetworkQualityLevel(newNetworkQualityLevel);

      setNetworkQualityLevel(participant.connectionQuality);
      participant.on(ParticipantEvent.ConnectionQualityChanged, handleNetworkQualityLevelChange);
      return () => {
        participant.off(ParticipantEvent.ConnectionQualityChanged, handleNetworkQualityLevelChange);
      };
    }
  }, [participant]);

  return networkQualityLevel;
}
