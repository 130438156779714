import { RoomConnectOptions, RoomOptions, TrackPublishOptions, type VideoResolution } from 'livekit-client';

const VIDEO_DIMENSIONS = {
  fullHd: { width: 1920, height: 1080 }, // 1080p
  hd: { width: 1280, height: 720 }, // 720p
  standard: { width: 858, height: 480 }, // 480p
  low: { width: 480, height: 360 }, // 360p
};
const DEFAULT_AUDIO_BITRATE = 16000;

export const CHAT_PAGE_SIZE = 50;
export const DICE_LOG_TOKEN = 'ROLE::DICE::';
export const PING_TOKEN = 'ROLE::PING::';

// TODO: LK allows aspectRatio here as well via Interface VideoResolution
export const getLocalVideoOptions = (mode?: 'premium' | 'screenshare'): VideoResolution  => {
  if (mode === 'premium') return { ...VIDEO_DIMENSIONS.hd, frameRate: 24 };
  if (mode === 'screenshare') return { ...VIDEO_DIMENSIONS.fullHd, frameRate: 15 };
  return { ...VIDEO_DIMENSIONS.standard, frameRate: 24 };
};

export const getAudioTrackPublishOptions = (overrides?: TrackPublishOptions) => {
  const options: TrackPublishOptions = {
    ...overrides,
  };
  return options;
}

export const getVideoTrackPublishOptions = (overrides?: TrackPublishOptions) => {
  const options: TrackPublishOptions = {
    ...overrides,
  };
  return options;
}

export const getCreateRoomOptions = (overrides?: RoomOptions) => {
  const options: RoomOptions = {
    /** in the case of mobile users, adaptiveStream should theoretically handle it; consider tweaking otherwise */
    // adaptiveStream :
    audioCaptureDefaults: {
      /** if we wanted to explore their noise suppression uncomment below */ 
      // noiseSuppression: true, 
    },
    videoCaptureDefaults : {
      resolution: getLocalVideoOptions(),
    },
    publishDefaults: {
      /** we can set some encoding defaults here that could be useful to tweak */
      // videoEncoding: {},
      audioPreset: {
        maxBitrate: DEFAULT_AUDIO_BITRATE,
      }
    },
    ...overrides,
  };
  return options;
}

export const getConnectOptions = (overrides?: RoomConnectOptions) => {
  const options: RoomConnectOptions = {
    /**
     * i think the defaults here are fine, but may be worth tweaking; dunno how to get network quality per person here, i think that has to be set at publish
     */

    ...overrides,
  };

  return options;
};
