import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import asset from './assetReducer';
import audioPlayer from './audioPlayerReducer';
import cards from './cardReducer';
import documents from './documentReducer';
import errors from './errorReducer';
import games from './gameReducer';
import general from './generalReducer';
import liveKit from './liveKitReducer';
import media from './mediaReducer';
import organizations from './organizationReducer';
import playkits from './playkitReducer';
import products from './productReducer';
import roll from './rollReducer';
import roomDocuments from './roomDocumentReducer';
import rooms from './roomReducer';
import roomSheets from './roomSheetReducer';
import roomUsers from './roomUserReducer';
import session from './sessionReducer';
import sheets from './sheetReducer';
import sheetTemplateBuilder from './sheetTemplateBuilderReducer';
import sheetTemplates from './sheetTemplateReducer';
import table from './tableReducer';
import twilio from './twilioReducer';
import users from './userReducer';

export default combineReducers({
  asset,
  audioPlayer,
  cards,
  documents,
  errors,
  form,
  games,
  general,
  liveKit,
  media,
  organizations,
  playkits,
  products,
  roll,
  roomDocuments,
  rooms,
  roomSheets,
  roomUsers,
  session,
  sheets,
  sheetTemplateBuilder,
  sheetTemplates,
  table,
  twilio,
  users,
});
