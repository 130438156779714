import { batch } from 'react-redux';
import { Dispatch } from 'redux';

import { createAction, createError, handleError } from './utilities';
import { TwilioSelector } from 'store/selectors';

import { Client as ConversationsClient } from '@twilio/conversations';
import role from 'apis/role';

export default class TwilioAction {
  static ADD_CONVERSATION = 'TwilioAction.ADD_CONVERSATION';
  static ADD_MESSAGE = 'TwilioAction.ADD_MESSAGE';
  static CONNECT_CHAT = 'TwilioAction.CONNECT_CHAT';
  static CONNECTING_CHAT = 'TwilioAction.CONNECTING_CHAT';
  static CREATE_CHAT = 'TwilioAction.CREATE_CHAT';
  static CREATE_DICE_CHAT = 'TwilioAction.CREATE_DICE_CHAT';
  static CREATE_PRIVATE_CHAT = 'TwilioAction.CREATE_PRIVATE_CHAT';
  static FETCH_TOKEN = 'TwilioAction.FETCH_TOKEN';
  static INCREMENT_UNREAD_COUNT = 'TwilioAction.INCREMENT_UNREAD_COUNT';
  static REMOVE_CONVERSATION = 'TwilioAction.REMOVE_CONVERSATION';
  static SELECT_CONVERSATION = 'TwilioAction.SELECT_CONVERSATION';
  static UPDATE_MESSAGES = 'TwilioAction.UPDATE_MESSAGES';
  static UPDATE_PAGINATOR = 'TwilioAction.UPDATE_PAGINATOR';
  static UPDATE_UNREAD_COUNT = 'TwilioAction.UPDATE_UNREAD_COUNT';

  static addConversation = (conversation) => {
    return createAction(TwilioAction.ADD_CONVERSATION, conversation);
  };

  static addMessage = (sid, message) => {
    return createAction(TwilioAction.ADD_MESSAGE, { sid, message });
  };

  static connectChat =
    (callback?: (client: ConversationsClient) => void) => async (dispatch: Dispatch, getState: () => any) => {
      const token = TwilioSelector.getToken(getState());
      dispatch(createAction(TwilioAction.CONNECTING_CHAT, true));
      return ConversationsClient.create(token).then(
        (response) => {
          batch(() => {
            dispatch(createAction(TwilioAction.CONNECTING_CHAT, false));
            dispatch(createAction(TwilioAction.CONNECT_CHAT, response));
          });
          callback?.(response);
        },
        (error) => {
          batch(() => {
            dispatch(createAction(TwilioAction.CONNECTING_CHAT, false));
            dispatch(
              createAction(
                TwilioAction.CONNECT_CHAT,
                createError({ message: `Could not connect to Twilio: ${error}` }),
                true
              )
            );
          });
        }
      );
    };

  static createChat =
    (guid: string, callback = null) =>
    async (dispatch) => {
      return role.post(`/rooms/${guid}/chat`).then(
        (response) => {
          const sid = response.data.sid;
          dispatch(createAction(TwilioAction.CREATE_CHAT, { guid, sid }));
          callback?.(sid);
        },
        ({ response }) => handleError(dispatch, TwilioAction.CREATE_CHAT, response)
      );
    };

  static createDiceChat =
    (guid: string, callback = null) =>
    async (dispatch) => {
      return role.post(`/rooms/${guid}/dice_chat`).then(
        (response) => {
          const sid = response.data.sid;
          dispatch(createAction(TwilioAction.CREATE_DICE_CHAT, { guid, sid }));
          callback?.(sid);
        },
        ({ response }) => handleError(dispatch, TwilioAction.CREATE_DICE_CHAT, response)
      );
    };

  static createPrivateChat =
    (guid: string, userId: string, callback = null) =>
    async (dispatch) => {
      return role.post(`/rooms/${guid}/private_chat`, { user_id: userId }).then(
        (response) => {
          const sid = response.data.sid;
          dispatch(createAction(TwilioAction.CREATE_PRIVATE_CHAT, { guid, sid }));
          callback?.(sid);
        },
        ({ response }) => handleError(dispatch, TwilioAction.CREATE_PRIVATE_CHAT, response)
      );
    };

  static fetchToken = (guid: string, callback?: (token: string) => void) => async (dispatch: Dispatch) => {
    return role.get(`/rooms/${guid}/chat_token`).then(
      (response) => {
        const { token } = response.data;
        dispatch(createAction(TwilioAction.FETCH_TOKEN, token));
        callback?.(token);
      },
      ({ response }) => handleError(dispatch, TwilioAction.FETCH_TOKEN, response)
    );
  };

  static incrementUnreadCount = (sid: string, count: number) => {
    return createAction(TwilioAction.INCREMENT_UNREAD_COUNT, { sid, count });
  };

  static removeConversation = (sid: string) => {
    return createAction(TwilioAction.REMOVE_CONVERSATION, sid);
  };

  static selectConversation = (name: string) => {
    return createAction(TwilioAction.SELECT_CONVERSATION, name);
  };

  static updateMessages = (sid: string, messages: any) => {
    return createAction(TwilioAction.UPDATE_MESSAGES, { sid, messages });
  };

  static updatePaginator = (sid: string, paginator: any) => {
    return createAction(TwilioAction.UPDATE_PAGINATOR, { sid, paginator });
  };

  static updateUnreadCount = (sid: string, count: number) => {
    return createAction(TwilioAction.UPDATE_UNREAD_COUNT, { sid, count });
  };
}
