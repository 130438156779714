import { RemoteParticipant } from 'livekit-client';
import { useState } from 'react';
import { VolumeControl } from 'room/AudioPlayer/AudioPlayer';
import styles from './UserVolume.module.css';

type UserVolumeProps = {
  participant?: RemoteParticipant;
};

export default function UserVolume({ participant }: UserVolumeProps) {
  const [volume, setVolume] = useState<number | undefined>(participant?.getVolume());
  if (!volume && !!participant?.getVolume()) {
    setVolume(participant.getVolume());
  }

  const setVolumeWithPromise = (participant: RemoteParticipant, volume: number, delay = 50): Promise<number> => {
    return new Promise((resolve) => {
      participant.setVolume(volume);

      setTimeout(() => {
        const newVolume = participant.getVolume();
        resolve(newVolume ?? 1);
      }, delay);
    });
  };

  const setVolumeHandler = async (participant: RemoteParticipant, volume: number) => {
    const newVolume = await setVolumeWithPromise(participant, volume);
    setVolume(newVolume);
  };

  return participant ? (
    <div className={styles.wrapper}>
      User Volume
      <div className={styles.controls}>
        <VolumeControl setVolume={(newVolume) => setVolumeHandler(participant, newVolume)} volume={volume ?? 1} />
      </div>
    </div>
  ) : null;
}
