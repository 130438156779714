import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Room, Track } from 'livekit-client';

import { getLocalVideoOptions } from 'models/Twilio';
import { SessionSelector } from 'store/selectors';

import { SCREENSHARE_TRACK_PREFIX } from 'video/utils';

interface MediaStreamTrackPublishOptions {
  name?: string;
}

export default function useScreenShareToggle(room: Room | null) {
  const currentUser = useSelector(SessionSelector.currentUser);
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareRef = useRef<() => void>();

  const shareScreen = useCallback(() => {
    if (!currentUser) return;
    return navigator.mediaDevices
      .getDisplayMedia({
        audio: false,
        video: getLocalVideoOptions('screenshare'),
      })
      .then((stream) => {
        const track = stream.getTracks()[0];

        // All video tracks are published with 'low' priority. This works because the video
        // track that is displayed in the 'MainParticipant' component will have it's priority
        // set to 'high' via track.setPriority()
        room!.localParticipant
          .publishTrack(track, {
            source: Track.Source.ScreenShare,
            name: `${SCREENSHARE_TRACK_PREFIX}${currentUser.id}`, // Tracks can be named to easily find them later
          } as MediaStreamTrackPublishOptions)
          .then(() => {
            stopScreenShareRef.current = () => {
              room!.localParticipant.unpublishTrack(track);
              track.stop();
              setIsSharing(false);
            };

            track.onended = stopScreenShareRef.current;
            setIsSharing(true);
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => {
        // Don't display an error if the user closes the screen share dialog
        if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
          console.error(error);
        }
      });
  }, [currentUser, room]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      return !isSharing ? shareScreen() : stopScreenShareRef.current?.();
    }
  }, [isSharing, shareScreen, stopScreenShareRef, room]);

  return [isSharing, toggleScreenShare] as const;
}
