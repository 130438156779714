import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocalAudioTrack, LocalVideoTrack, createLocalAudioTrack, createLocalVideoTrack, createLocalTracks, type AudioCaptureOptions, type VideoCaptureOptions, type CreateLocalTracksOptions } from 'livekit-client';

import { getLocalVideoOptions } from 'models/LiveKit';
import { LiveKitAction } from 'store/actions';
import { SessionSelector, LiveKitSelector } from 'store/selectors';

import { getDeviceInfo, isPermissionDenied } from 'video/utils';

export default function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);

  const dispatch = useDispatch();
  const currentUser = useSelector(SessionSelector.currentUser);
  const deviceSettings = useSelector(LiveKitSelector.getSettings);

  const getLocalAudioTrack = useCallback(
    async (deviceId?: string) => {
      const selectedAudioDeviceId = deviceId ?? deviceSettings.audioDevice;

      const { audioInputDevices } = await getDeviceInfo();

      const hasSelectedAudioDevice =
        selectedAudioDeviceId && audioInputDevices.some((device) => device.deviceId === selectedAudioDeviceId);
        const options: AudioCaptureOptions = {
        ...(hasSelectedAudioDevice && { deviceId: { exact: selectedAudioDeviceId } }),
      };

      return createLocalAudioTrack(options).then((newTrack) => {
        setAudioTrack(newTrack);
        return newTrack;
      });
    },
    [currentUser?.username, deviceSettings.audioDevice]
  );

  const getLocalVideoTrack = useCallback(
    async (deviceId?: string) => {
      const selectedVideoDeviceId = deviceId ?? deviceSettings.videoDevice;

      const { videoInputDevices } = await getDeviceInfo();

      const hasSelectedVideoDevice =
        selectedVideoDeviceId && videoInputDevices.some((device) => device.deviceId === selectedVideoDeviceId);

      const options: VideoCaptureOptions = {
        resolution: {...getLocalVideoOptions()},
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
      };

      return createLocalVideoTrack(options).then((newTrack) => {
        setVideoTrack(newTrack);
        return newTrack;
      });
    },
    [currentUser?.username, deviceSettings.videoDevice]
  );

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const getAudioAndVideoTracks = useCallback(async () => {
    const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } = await getDeviceInfo();

    if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve();
    if (isAcquiringLocalTracks || audioTrack || videoTrack) return Promise.resolve();

    setIsAcquiringLocalTracks(true);

    const selectedAudioDeviceId = deviceSettings.audioDevice;
    const selectedVideoDeviceId = deviceSettings.videoDevice;

    const hasSelectedAudioDevice = audioInputDevices.some(
      (device) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    );
    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isCameraPermissionDenied = await isPermissionDenied('camera');
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone');

    const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied;
    const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied;

    const localTrackConstraints: CreateLocalTracksOptions = {
      video: shouldAcquireVideo && {
        resolution: {...getLocalVideoOptions()},
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
      },
      audio:
        shouldAcquireAudio &&
        (hasSelectedAudioDevice ? { deviceId: { exact: selectedAudioDeviceId! } } : hasAudioInputDevices),
    };

    return createLocalTracks(localTrackConstraints)
      .then((tracks) => {
        const newVideoTrack = tracks.find((track) => track.kind === 'video') as LocalVideoTrack;
        const newAudioTrack = tracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
        if (newVideoTrack) {
          setVideoTrack(newVideoTrack);
          // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
          // in cases where the user's video is disabled.
          dispatch(LiveKitAction.updateSettings({ videoDevice: newVideoTrack.mediaStreamTrack.getSettings().deviceId }));
        }
        if (newAudioTrack) {
          setAudioTrack(newAudioTrack);

          dispatch(LiveKitAction.updateSettings({ audioDevice: newAudioTrack.mediaStreamTrack.getSettings().deviceId }));
        }

        // These custom errors will be picked up by the MediaErrorSnackbar component.
        if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
          const error = new Error();
          error.name = 'NotAllowedError';
          throw error;
        }

        if (isCameraPermissionDenied) {
          throw new Error('CameraPermissionsDenied');
        }

        if (isMicrophonePermissionDenied) {
          throw new Error('MicrophonePermissionsDenied');
        }
      })
      .finally(() => setIsAcquiringLocalTracks(false));
  }, [
    audioTrack,
    currentUser?.username,
    deviceSettings.audioDevice,
    deviceSettings.videoDevice,
    dispatch,
    isAcquiringLocalTracks,
    videoTrack,
  ]);

  const localTracks = [audioTrack, videoTrack].filter((track) => track !== undefined) as (
    | LocalAudioTrack
    | LocalVideoTrack
  )[];

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  };
}
