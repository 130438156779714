import { useEffect, useState } from 'react';
import { LocalParticipant, RemoteParticipant, Room, RoomEvent } from 'livekit-client';

/**
 * This hook returns an array of the video room's participants. Unlike the hooks
 * "useSpeakerViewParticipants" and "useGalleryViewParticipants", this hook does not reorder
 * the list of participants whenever the dominantSpeaker changes. This will prevent unnecessary re-renders because components that use this hook will only update when a participant connects to or disconnects from the room.
 */
export default function useParticipants(room: Room | null) {
  const [participants, setParticipants] = useState<(RemoteParticipant | LocalParticipant)[]>([]);
  
  useEffect(() => {
    if (room) {
      // Set the initial participants (anyone in the room who was already connected)
      const allParts = [...Array.from(room.remoteParticipants.values()), room.localParticipant];

      setParticipants(allParts);

      const participantConnected = (participant: RemoteParticipant) =>
        setParticipants((prevParticipants) => [...prevParticipants, participant]);

      const participantDisconnected = (participant: RemoteParticipant) =>
        setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));

      room.on(RoomEvent.ParticipantConnected, participantConnected);
      room.on(RoomEvent.ParticipantDisconnected, participantDisconnected);
      return () => {
        room.off(RoomEvent.ParticipantConnected, participantConnected);
        room.off(RoomEvent.ParticipantDisconnected, participantDisconnected);
      };
    }
  }, [room]);

  return participants;
}
